import React from 'react';
import dayjs from 'dayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';

const MaterialDatePicker = (props) => {
  const today = dayjs();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        format="DD-MM-YYYY"
        className={props.className || ""}
        value={props.value}
        onChange={props.onChange}
        renderInput={(params) => <TextField {...params}/>}
        maxDate={today}
      />
    </LocalizationProvider>
  );
};

export default MaterialDatePicker;
